@import url("https://fonts.googleapis.com/css?family=Work+Sans:regular,bold,italic&subset=latin,latin-ext");

:root {
    --color-text: #fff;
    --color-text-second: #430046;
    --main-radius: 20px;
}

html {
    min-height: 100%;
}

body {
    /*Here is your body CSS code*/
    background-size: auto 100%; /*Just add this for full size*/
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.private {
    position: relative;
}

.private::after {
    content: "";
    display: block;
    position: absolute;
    background-image: url("../assets/images/lock.png");
    background-repeat: no-repeat;
    background-color: rgba(255, 255, 255, 0.3);
    border: 3px solid #fff;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    right: -15px;
    bottom: -15px;
    text-align: center;
    background-position: center;
}

.league--big--item.private::after {
    left: 0;
    right: auto;
    bottom: 15px;
}

.error--msg {
    background-color: rgba(245, 5, 74, 0.4);
    border: 2px solid rgba(245, 5, 74, 0.4);
    padding-left: 5px;
}

@media (min-width: 1050px) {
    .page {
        padding: 20px 5% 50px;
        width: 70%;
    }

    .section__header {
        border-right: 1px solid rgba(255, 255, 255, 0.3);
    }
}

/* Rules box */
.rules--container {
    background: #f7f8fc;
    color: #020202;
    font-size: 16px;
    text-align: center;
    position: relative;
    z-index: 1;
}

.rules--container p {
    margin: 0;
    padding: 5px 30px;
}

.rules--container a {
    margin: 0;
}

.rules--container button {
    color: var(--color-primary);
    background: none;
    border: none;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.basic--btn.login--link {
    color: var(--color-text);
    text-decoration: none;
    display: block;
    width: auto;
    padding: 10px 30px;
    line-height: initial;
    height: auto;
}

.user--login--box img {
    height: 40px;
    width: 40px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
    border-radius: 60px;
    border: 5px solid rgba(0, 0, 0, 0);
    margin-right: 15px;
}

.user--login--box span {
    margin-top: -15px;
    display: inline-block;
    top: -20px;
    position: relative;
    font-size: 18px;
    font-weight: 600;
}

.user--options {
    display: flex;
    margin: 0;
    padding: 0;
}

.user--options li {
    list-style: none;
    padding-right: 10px;
}

.user--options .basic--btn {
    height: auto;
    padding: 0 10px;
}

.user--options li a {
    font-size: 14px;
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
}

.horizontal--container {
    overflow: auto;
}

.basic--form input {
    display: block;
    width: 95%;
    margin-bottom: 20px;
    font-size: 28px;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    padding: 5px;
}

.basic--link {
    display: inline-block;
    margin-top: 15px;
    color: #f7054a;
}

.basic--btn {
    height: 55px;
    width: 282px;
    background-color: #f7054a;
    box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.1);
    color: #ffffff;
    font-family: "Work Sans";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.75px;
    line-height: 27.25px;
    text-align: center;
    border-radius: 10px;
    border: none;
    display: block;
    cursor: pointer;
}

a.basic--btn {
    text-decoration: none;
    padding: 15px 0;
    height: auto;
}

.leagues--list {
    display: flex;
    padding: 0;
}

.cta--auth {
    text-align: center;
    padding: 10px 0;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    margin-top: 40px;
    font-size: 20px;
    cursor: pointer;
}

.cta--auth a {
    color: #fff;
    text-decoration: none;
}

.cta--auth .cta--more span {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50px;
    padding: 10px 21px 15px;
    font-size: 30px;
}

.leagues--list div {
    margin-right: 20px;
    border-radius: var(--main-radius);
}

.leagues--list div .league--link {
    display: block;
    width: 190px;
    height: 150px;
    background-image: linear-gradient(
            45deg,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0)
    );
    position: relative;
    border-radius: var(--main-radius);
    overflow: hidden;
}

.leagues--list div .league--link:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5);
    border-radius: var(--main-radius);
}

.leagues--list div .league--link span {
    position: absolute;
    left: 10px;
    bottom: 10px;
    color: var(--color-text);
    font-family: "Work Sans";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.5px;
    line-height: 20px;
    text-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.05);
}

.matches--list {
    display: flex;
    padding: 0;
    padding: 3px 0 10px;
}

.match--tile {
    background: #ffffff;
    border-radius: var(--main-radius);
    width: 330px;
    height: 250px;
    margin-right: 20px;
    box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.3);
}

.match--tile .match--info {
    border-bottom: 1px solid #999999;
    color: var(--color-text-second);
    padding: 15px 25px;
    display: flex;
    justify-content: space-between;
}

.match--tile .match--info span:first-child {
    height: 16px;
    opacity: 0.8;
    font-family: "Work Sans";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.5px;
}

.match--tile .match--info span:last-child {
    height: 16px;
    opacity: 0.4;
    color: #430046;
    font-family: "Work Sans";
    font-size: 13px;
    letter-spacing: -0.5px;
    line-height: 16px;
    text-align: right;
}

.match--tile .match--teams {
    padding: 20px 25px 0;
    display: flex;
    justify-content: space-around;
}

.match--tile .match--teams > div {
    text-align: center;
}

.match--tile .match--teams .team span {
    height: 16px;
    width: 54px;
    color: #020202;
    font-family: "Work Sans";
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.27px;
    line-height: 16px;
    text-align: center;
}

.match--tile .team img {
    display: block;
    width: 50px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
    border-radius: 30px;
    border: 5px solid rgba(0, 0, 0, 0);
}

.match--tile .match--date {
    padding: 15px 0 0;
}

.match--tile .match--date .match--hour {
    background-color: rgba(67, 0, 70, 0.1);
    color: rgba(67, 0, 70, 0.6);
    font-family: "Work Sans";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 14px;
    text-align: center;
    border-radius: 20px;
    padding: 10px 20px;
}

.match--tile .match--date .match--day {
    opacity: 0.3;
    color: #430046;
    font-family: "Work Sans";
    font-size: 13px;
    letter-spacing: -0.27px;
    line-height: 16px;
    text-align: center;
    margin: 25px 0 0;
    display: block;
}

.match--tile .add--bet {
    margin: 15px 25px 0;
    display: block;
}

.match--tile .text--btn {
    padding: 0;
    margin: 0;
}

.match--tiny_tile.live {
    border: 3px solid #f7054a;
    margin-top: -3px;
    position: relative;
}

.match--tiny_tile.live:after {
    content: "LIVE";
    background: #f7054a;
    position: absolute;
    border-radius: 3px;
    padding: 0 10px;
    font-size: 12px;
    top: -3px;
    left: 62px;
}

/*Match : Tiny tile */
.match--tiny_tile {
    background: rgba(255, 255, 255, 0.3);
    height: 105px;
    margin-right: 10px;
    width: auto;
}

.match--tiny_tile .match--teams {
    width: 140px;
    padding: 15px;
}

.match--tiny_tile .team img {
    display: block;
    width: 30px;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    border: 3px solid rgba(0, 0, 0, 0);
}

.match--tiny_tile .match--score {
    font-size: 16px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.7);
    margin: 6px 5px 0;
}

.match--tiny_tile .bet--result {
    padding: 7px 20px;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.match--tiny_tile .bet--result .bet-eval {
    font-weight: bold;
}

/*Match : List item */
.match--list_item {
    background: rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    width: auto;
    padding: 20px 20px 20px 30px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.match--list_item .match--teams {
    width: 100px;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.match--list_item .team {
    text-align: center;
}

.match--list_item .team img {
    display: block;
    width: 30px;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    border: 3px solid rgba(0, 0, 0, 0);
}

.match--list_item .match--score {
    margin-top: 20px;
}

.match--list_item .match--score span {
    font-size: 20px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.7);
    background: rgba(255, 255, 255, 0.2);
    border-radius: 30px;
    padding: 13px 20px;
}

.match--list_item .match--score span.live {
    border: 3px solid #f7054a;
    margin-top: -3px;
    position: relative;
}

.match--list_item .match--score span.live:after {
    content: "LIVE";
    background: #f7054a;
    position: absolute;
    border-radius: 3px;
    padding: 0 10px;
    font-size: 12px;
    top: -9px;
    left: 17px;
}

.match--list_item .bet--result {
    padding: 0 20px;
    font-size: 15px;
    justify-content: space-between;
    position: relative;
}

.match--list_item .match--date {
    padding-top: 20px;
}

.match--list_item .bet--result p {
    font-size: 20px;
}

.match--list_item .bet--result .bet--score {
    position: relative;
    font-weight: bolder;
    font-size: 20px;
    padding-right: 40px;
}

.match--list_item .bet--result .bet--score:after {
    content: "";
    position: absolute;
    height: 25px;
    width: 20px;
    background-image: url(../assets/images/icon-arrow.svg);
    background-repeat: no-repeat;
    background-size: contain;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.match--list_item .bet--result .bet--score--after {
    position: relative;
    font-weight: bolder;
    font-size: 14px;
    margin: 0;
    padding: 0;
    text-align: center;
    margin-top: 5px;
    padding-bottom: 5px;
}

.match--list_item .bet--result .bet--points {
    text-align: center;
    position: relative;
    font-weight: bolder;
    font-size: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    margin: 0;
    padding: 5px 10px 0;
}

.match--odds {
    background-color: rgba(67, 0, 70, 0.05);
    display: flex;
    justify-content: space-around;
    padding: 10px;
    margin: 20px;
    border-radius: 20px;
}

.match--odds p {
    color: #430046;
    text-align: center;
    font-size: 16px;
}

.match--odds p span {
    display: block;
    font-size: 26px;
}

.user--ranking {
    padding: 0;
}

.user--ranking li {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 40px;
    display: flex;
    height: 80px;
    margin: 0 0 10px;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.5);
    cursor: pointer;
    align-items: center;
}

.user--ranking li img {
    display: block;
    width: 50px;
    height: 50px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
    border-radius: 30px;
    border: 5px solid rgba(0, 0, 0, 0);
}

.user--ranking li .points {
    color: rgba(255, 255, 255, 0.5);
}

.user--ranking li .user--img {
    padding: 0 20px 0 12px;
    position: relative;
    align-content: center;
    z-index: 1;
}

.user--ranking li .user--img .user--position {
    display: block;
    box-shadow: 0 0 0 2px rgb(0, 0, 0, 0.5);
    border-radius: 10px;
    width: 20px;
    text-align: center;
    height: 20px;
    font-size: 14px;
    background: rgba(0, 0, 0, 0.8);
    float: right;
    margin-top: -20px;
    z-index: 100;
    position: relative;
}

.user--ranking li .match--tiny_tile {
    margin-left: auto;
    height: auto;
    border-radius: 30px;
    padding: 5px;
    margin-right: 15px;
}

.user--ranking li .match--tile .match--teams {
    padding: initial;
    width: initial;
}

.user--ranking li .match--tiny_tile img {
    width: 30px;
    height: 30px;
}

.league--header {
    display: flex;
    align-items: flex-start;
}

.league--header h2 {
    margin-top: 0;
}

.league--header .league--blazon {
    height: 170px;
    width: 170px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
    border-radius: 30px;
    border: 5px solid rgba(0, 0, 0, 0);
    margin-right: 30px;
    background-color: rgba(0, 0, 0, 0.1);
    object-fit: contain;
}

.league--header .league--price {
    font-size: 24px;
}

.league--header .league--price::before {
    content: "\1F947";
    padding-right: 5px;
}

.league--options {
    display: flex;
}

.league--options .league--join--btn {
    margin-right: 20px;
}

.search--box {
    margin-top: 20px;
}

.search--box input {
    width: 96%;
    padding: 1% 2%;
    font-size: 30px;
    margin: 0 0 20px;
}

.leagues--list__complete {
}

.leagues--list__complete .league--big--item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.league--big--item .league--link {
    display: flex;
    color: #fff;
    text-decoration: none;
    position: relative;
}

.league--big--item .league--link:after {
    content: "";
    position: absolute;
    height: 37px;
    width: 30px;
    background-image: url(../assets/images/icon-arrow.svg);
    background-repeat: no-repeat;
    background-size: contain;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
}

.league--big--item .league--blazon {
    height: 80px;
    width: 80px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
    border-radius: 60px;
    border: 5px solid rgba(0, 0, 0, 0);
    margin-right: 30px;
}

.league--big--item .league--link h2 {
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 5px;
}

.league--join--container {
    background: #fff;
    padding: 15px;
    border-radius: 15px;
    position: absolute;
    margin-top: 20px;
}

.league--join--container p {
    color: var(--color-text-second);
    font-size: 14px;
}

.league--join--container input {
    display: block;
    font-size: 20px;
}

.league--share {
    margin-left: 20px;
}

.league--share button {
    margin-top: 10px;
    margin-left: 10px;
}

/*Footer*/
footer {
    font-size: 12px;
}

/*Page match*/
.match--container {
    background: #f7f8fc;
    border-radius: 20px;
    padding-bottom: 20px;
}

.match--container .match--info {
    display: flex;
    color: #020202;
    padding: 15px;
    margin-bottom: 30px;
    justify-content: flex-end;
    font-size: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.match--container .match--info span {
    display: block;
    padding-left: 25px;
    padding-right: 25px;
    border-left: 1px solid rgba(0, 0, 0, 0.5);
}

.match--container .match--info span:first-child {
    border-left: none;
}

.match--container .match--teams {
    display: flex;
    justify-content: space-around;
    color: #020202;
}

.match--container .team {
    text-align: center;
    font-size: 26px;
}

.match--container .team span {
    padding: 20px;
}

.match--container .team img {
    width: 200px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
    border-radius: 115px;
    border: 5px solid rgba(0, 0, 0, 0);
}

.match--container .match--score {
    font-weight: bold;
    color: rgba(67, 0, 70, 0.4);
    margin-top: 80px;
}

.match--score span {
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(67, 0, 70, 0.05);
    border-radius: 30px;
    padding: 10px 15px;
}

.bet--container {
    margin-top: 20px;
    text-align: center;
}

.bet--container .bet--form {
    background: rgba(247, 5, 74, 0.1);
    display: flex;
    padding: 30px 20px 10px;
    justify-content: space-around;
    font-size: 46px;
    color: #430046;
}

.bet--container .bet--form .team--score {
    background-color: #fff;
    width: 80px;
    height: 75px;
    border-radius: 20px;
    margin: 10px 0;
    padding-top: 5px;
}

.bet--container .bet--form select {
    width: 60px;
    height: 80px;
    border-radius: 20px;
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.3);
    border: none;
    text-align: center;
    padding: 0 10px;
}

.bet--container .bonus--selector {
    position: relative;
    top: 30px;
}

.bet--container .bonus--selector p {
    font-size: 14px;
    margin-bottom: 5px;
}

.bet--container .bonus--selector select {
    border-radius: 50px;
    width: 80px;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-align: center;
    padding-top: 4px;
}

.bet--container .basic--btn {
    margin: 35px auto 0;
}

.bet--container .team--score--container button {
    display: block;
    width: 40px;
    height: 50px;
    background: url(../assets/images/icon-arrow.svg) no-repeat;
    background-size: contain;
    border: none;
    margin-top: -20px;
    margin-left: auto;
    margin-right: auto;
}

.bet--container .team--score--container button.arrow--up {
    transform: rotate(-90deg);
    margin-bottom: 10px;
}

.bet--container .team--score--container button.arrow--down {
    transform: rotate(90deg);
    margin-top: 10px;
}

.bet--container .team--score--container button.arrow--down.inactive {
    opacity: 0.5;
}

.bets--list {
    display: grid;
    padding: 0;
    grid-template-columns: repeat(auto-fill, 180px);
    justify-content: space-between;
    grid-gap: 30px;
}

@media (max-width: 500px) {
    h2 {
        font-size: 22px;
    }

    .basic--btn {
        height: 35px;
        width: 162px;
    }

    .page__scroll > div {
        padding-bottom: 100px;
    }

    .match--tile .add--bet,
    .bet--container .basic--btn {
        margin: 15px auto 0;
        display: block;
        width: 250px;
    }

    .match--list_item {
        padding: 10px 10px 10px 20px;
    }

    .match--list_item .team {
        font-size: 14px;
    }

    .match--list_item .match--score {
        margin-top: 15px;
        text-align: center;
    }

    .match--list_item .match--score span {
        font-size: 14px;
    }

    .match--list_item .match--date {
        display: none;
    }

    .match--list_item .basic--btn {
        width: auto;
        height: auto;
        padding-left: 5px;
        padding-right: 5px;
    }

    .match--list_item .bet--result {
        padding: 0 20px 0 0;
    }

    .match--list_item .bet--result .basic--btn {
        margin-top: 15px;
    }

    .match--list_item .bet--result .bet--score {
        font-size: 16px;
        padding-right: 20px;
    }

    .match--list_item .bet--result .bet--score:after {
        content: "";
        position: absolute;
        height: 17px;
        width: 10px;
        background-repeat: no-repeat;
        background-size: contain;
        right: -10px;
        top: 50%;
        transform: translateY(-50%);
    }

    .match--container .match--info {
        padding: 15px 0;
        justify-content: space-around;
        font-size: 11px;
    }

    .match--container .team {
        width: 180px;
    }

    .match--container .team img {
        width: 80px;
    }

    .match--container .team p {
        margin: 0;
        font-size: 20px;
    }

    .match--container .match--score {
        margin-top: 40px;
        text-align: center;
        width: 150px;
    }

    .match--container .match--score span {
        font-size: 20px;
    }

    .bet--container .bet--form input {
        padding-left: 9px;
        padding-right: 9px;
    }

    .league--header .league--blazon {
        height: 80px;
        width: 80px;
        border-radius: 15px;
        margin-right: 20px;
    }

    .league--header h2 {
        margin-top: 0;
        margin-bottom: 0;
    }

    .league--header .league--price {
        font-size: 14px;
        margin-top: 5px;
        padding-top: 0;
    }

    .league--options {
        display: block;
    }

    .league--share {
        display: block;
        margin-left: 0;
    }

    .league--share button {
        margin-left: 5px;
    }

    footer {
        text-align: center;
        margin-top: -120px;
    }

    .user--ranking li .user--name {
        max-width: 129px;
        overflow: hidden;
    }
}

.user--options .basic--btn {
    width: auto;
    height: auto;
}

.user--options .basic--btn.edit--user--btn {
    margin-right: 10px;
}

.logout--btn {
    background-color: #999999;
}


.content--rules li {
    line-height: 35px;
}